import Link from 'next/link';
import Container from '../Container';
import NoPrefetchLink from '../NoPrefetchLink';

interface Props {
  title?: string;
  subtitle?: string;
  link?: { text: string; href: string };
}

function UnderConstructionPage({
  title = 'Side under utvikling!',
  subtitle = '   Du har kommet til en side under oppbygging. Vennligst prøv igjen senere eller naviger tilbake til forsida.',
  link = { text: 'Forside', href: '/' },
}: Props) {
  return (
    <Container
      noPadding
      className="flex h-full flex-col items-center justify-center gap-8 px-6 py-60 md:gap-14"
    >
      <div className="flex flex-col gap-4">
        <h1 className="text-center text-5xl font-semibold text-slate-700 md:text-6xl">
          Oops...
          <br />
          {title}
        </h1>
        <p className="max-w-prose text-center text-base font-medium text-gray-500 md:text-lg">
          {subtitle}
        </p>
      </div>

      <NoPrefetchLink
        href="/"
        className="ease bg-secondary hover:bg-primary shadow-secondary/50 hover:shadow-primary/50 rounded-[24px] px-10 py-2 text-base font-medium tracking-wide text-white shadow-lg duration-300 hover:rounded-md active:scale-[98%] active:shadow-inner md:text-lg"
      >
        {link.text}
      </NoPrefetchLink>
    </Container>
  );
}

export default UnderConstructionPage;
