import Script from 'next/script';
import { ArticleStandardPageType } from '../../types';
import PortableText from '../PortableText';
import HeroCTA from '../HeroCTA';
import SchemaRenderer from '../schemaRenderer';
import { StandardArticleList } from '../StandardArticleList';
import { FeatureArticleList } from '../FeatureArticleList';

interface PageProps {
  article: ArticleStandardPageType;
}

function StandardArticlePage({ article }: PageProps) {
  const {
    overline,
    title,
    description,
    bodyText,
    showVacancies,
    advertId,
    image,
    relatedArticlesFeature,
    relatedArticlesStandard,
    parent,
    loginOverride,
    formOverride,
    seo,
  } = article;

  const isConverting = ['service', 'buyCarPage'].includes(parent._type) || parent.isConverting;

  const schemaMarkup = seo?.schemaMarkup;

  return (
    <div>
      <HeroCTA
        overline={overline}
        title={title}
        description={description}
        image={image}
        primaryButton={
          loginOverride?.login
            ? {
                href: loginOverride.login.cta.url,
                linkText: loginOverride.linkText || loginOverride.login.cta.linkText,
              }
            : undefined
        }
        formOverride={formOverride}
        isBeige={!isConverting}
      />

      {bodyText && (
        <div className="mx-auto my-10 max-w-3xl px-6 sm:px-10 lg:my-20">
          <PortableText value={bodyText} />
        </div>
      )}
      {schemaMarkup && (
        <SchemaRenderer
          schemaMarkup={schemaMarkup}
          pageContent={article}
          alwaysShowTypes={['Article']}
        />
      )}
      {relatedArticlesStandard.length > 0 && (
        <StandardArticleList articles={relatedArticlesStandard} />
      )}
      {Array.isArray(relatedArticlesFeature) && !!relatedArticlesFeature.length && (
        <FeatureArticleList articles={relatedArticlesFeature} />
      )}

      {/**
       * The script from reachmee looks for a div with id matching the last part of the script url.
       * In this case: 682-39
       *  */}
      {showVacancies && (
        <>
          <div id="reachmee-iframe-682-39" />
          <Script id="myScript">{`
              //from db
              var iFrameUrl = 'https://web106.reachmee.com/ext/';
              var validator = '5b94aad8994cc735cb63b442237c630f';
              var iid = 'I008';
              var customer = '682';
              var site = '39';
              var iHelperUrl = 'https://mollerbil.no/om-oss/jobb/';
              var height = '4000';
              var langDef = 'NO';
              var title = 'ReachMee Rekruttering ekstern nettside';
              var ref = encodeURIComponent(document.referrer);

              // form url
              var jobId = '';
              var destPage = '';
              var langId = '';
              var rmproj = '';

              //
              function getQueryVariable(variable) {
              if (variable.toLowerCase() == 'rmurl') {
              var query = decodeURIComponent(window.location.search.substring(1));
              } else {
              var query = window.location.search.substring(1);
              }
              var vars = query.split('&');
              for (var i=0;i<vars.length;i++)
              {
              var pair = vars[i].split('=');
              if (pair[0] == variable) {
              if (pair.length > 2) return pair[2];
              return pair[1];
              }
              }
              }

              iFrameUrl += iid+'/'+customer+'/';

              destPage = getQueryVariable('rmpage') || "apply";
              jobId = getQueryVariable('rmjob') || ${advertId};
              langId = getQueryVariable('rmlang');
              rmproj = getQueryVariable('rmproj');
              rmihelper = getQueryVariable('rmihelper');

              if (typeof destPage == 'undefined' || destPage == ''){
              destPage = 'main?';
              }
              else{
              destPage += '?';
              }
              if (destPage == 'job?' || destPage == 'apply?' || destPage == 'application?') {
              if (typeof jobId == 'undefined' || jobId == ''){
              destPage = 'main?';
              }
              else{
              destPage += 'job_id='+jobId+'&';
              }
              }

              if (destPage == 'assessment?') {
              if (typeof rmproj == 'undefined' || rmproj == ''){
              destPage = 'main?';
              }
              else{
              destPage += 'commseqno='+rmproj+'&';
              }
              }

              if (destPage == 'booking?') {
              if (typeof rmproj == 'undefined' || rmproj == '' || jobId == 'undefined' || jobId == ''){
              destPage = 'main?';
              }
              else{
              destPage += 'commseqno='+rmproj+'&job_id='+jobId+'&';
              }
              }

              // oldstyleurl
              var rmurl = '';
              rmurl = getQueryVariable('RMURL');
              if ((typeof rmurl != 'undefined') && (rmurl.length>0)){
              destPage = 'job?job_id='+rmurl+'&';
              }
              rmurl = getQueryVariable('rmurl');
              if ((typeof rmurl != 'undefined') && (rmurl.length>0)){
              destPage = 'job?job_id='+rmurl+'&';
              }

              rmtoken = getQueryVariable('rmtoken');
              if (destPage == 'subscription?' || destPage == 'subscriptions?') {
              if (typeof rmtoken == 'undefined' || rmtoken == ''){
              destPage = 'main?';
              }
              else{
              destPage += 'skey='+rmtoken+'&';
              }
              }

              if (destPage == 'profileactivate?') {
              var skey = getQueryVariable('skey');
              if (typeof skey == 'undefined' || skey == ''){
              destPage = 'main?';
              }
              else{
              destPage += 'skey='+skey+'&';
              }
              }

              iFrameUrl += destPage;
              iFrameUrl += 'site='+site+'&validator='+validator;

              if (typeof langId == 'undefined' || langId == ''){
              langId = langDef;
              }

              var cantoken = getQueryVariable('cantoken');
              if (cantoken) {
              iFrameUrl += '&cantoken=' + cantoken;
              }

              iFrameUrl += '&lang='+langId;

                  var cookie_name = "rm_reftracker_682";
                  var cookie_parts = ("; " + document.cookie).split("; " + cookie_name + "=");

                  if (cookie_parts.length == 2) {
                      var cookie_value = cookie_parts.pop().split(";").shift();
                      if (cookie_value !== "") {
                          ref = cookie_value.replace(/^"(.*)"$/, '$1');;
                      }
                  }

                  //If referer header is empty, check for QueryString ref
                  if(ref==null || ref == undefined || ref == '') {
                      ref = getQueryVariable('ref');
                  }
                  if(ref==undefined || ref.length==0) {
                      ref = '';
                  }

                  if(ref.length > 0) {
                      ref = ref.toLowerCase();
                      ref = (ref.indexOf('t.co') > -1) ? 'Twitter' : ref;
                  }

                  iFrameUrl += '&ref='+ref; //referrer

              // add iHelperURL
              if (iHelperUrl != '' && iHelperUrl != undefined) {
              iFrameUrl += '&ihelper='+iHelperUrl;
              }


              // Resize iframe to full height
              function resizeIframe(height)
              {
              // +60 is a general rule of thumb to allow for differences in
              // IE & and FF height reporting, can be adjusted as required..
              document.getElementById('riframe').height = parseInt(height) + 60;

            }
              function scrollToIframe(iframeElement) {

                if (iframeElement.dataset.loadedFirstTime) {
                  iframeElement.scrollIntoView(true);
                }
                iframeElement.dataset.loadedFirstTime = true;
              }

              if (!rmihelper){

                var iframe = document.createElement("iframe");
                iframe.setAttribute('allowTransparency', 'true');
                iframe.setAttribute('title', title);
                iframe.setAttribute('id', 'riframe');
                iframe.setAttribute('name', 'riframe');
                iframe.setAttribute('onload', 'scrollToIframe(this)');
                iframe.setAttribute('width', '100%');
                iframe.setAttribute('height', height);
                iframe.setAttribute('frameborder', '0');
                iframe.setAttribute('src', iFrameUrl);

                var target_element = document.getElementById("reachmee-iframe-" + customer + "-" + site);
                if (target_element) {
                  target_element.appendChild(iframe);
                } else {
                  var wrap = document.createElement('div');
                  wrap.appendChild(iframe);
                  document.write(wrap.innerHTML);
                }

                // Listen for messages sent from the iFrame
                var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
                var eventer = window[eventMethod];
                var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

                eventer(messageEvent,function(e) {
                if (e.data.indexOf && {}.toString.call(e.data.indexOf) === '[object Function]')
                  if (e.data.indexOf('resize::') != -1) {
                    var height = e.data.replace('resize::', '');
                    document.getElementById('riframe').style.height = (parseInt(height))+'px';
                  }
                } ,false);

              }
            `}</Script>
        </>
      )}
    </div>
  );
}

export default StandardArticlePage;
