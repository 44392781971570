import { PortableTextBlock } from '@portabletext/types';
import { FaCheck } from 'react-icons/fa6';
import { HeroVehicleValuation } from '../HeroVehicleValuation';
import { ArticleCardType, DefaultSEOType, ImageWithAltType, SEOType } from '../../types';
import { BenefitType, ValuationStepType } from '../../types/vehicleValuation';
import PortableText from '../PortableText';
import { StandardArticleList } from '../StandardArticleList';
import { FeatureArticleList } from '../FeatureArticleList';

export type VehicleValuationPageType = {
  _type: 'vehicleValuationPage';
  seo: SEOType;
  defaultSEO: DefaultSEOType;
  overline?: string;
  description?: string;
  title?: string;
  benefitsTitle?: string;
  benefitsDescription?: string;
  benefits?: BenefitType[];
  valuationStepsTitle?: string;
  valuationStepsDescription?: string;
  valuationSteps?: ValuationStepType[];
  image?: ImageWithAltType;
  bodyText?: PortableTextBlock[];
  featureArticles: ArticleCardType[];
  standardArticles: ArticleCardType[];
};

interface PageProps {
  content: VehicleValuationPageType;
}

function ValuationStepsComponent({
  valuationSteps,
  valuationStepsTitle,
  valuationStepsDescription,
}: {
  valuationSteps: ValuationStepType[];
  valuationStepsTitle: string;
  valuationStepsDescription: string;
}) {
  return (
    <section>
      <div className="max-w-8xl mx-auto px-6 pt-28 lg:pt-0 pb-16 sm:px-10 sm:pb-20 lg:px-20 lg:pb-28">
        <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">{valuationStepsTitle}</h2>
        {valuationStepsDescription && <p className="mt-2 text-xl">{valuationStepsDescription}</p>}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-10">
          {valuationSteps?.map((step, index) => (
            <div key={step._key} className="flex flex-col gap-4 bg-[#F3EEE8] p-8">
              <div className="bg-primary rounded-full w-8 h-8 flex items-center justify-center">
                <p className="text-white font-semibold">{index + 1}</p>
              </div>
              <h3 className="mb-2 break-words text-2xl font-semibold">{step.title}</h3>
              <p className="text-on-info-container lg:text-lg">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

function BenefitsComponent({
  benefits,
  benefitsTitle,
  benefitsDescription,
}: {
  benefits: BenefitType[];
  benefitsTitle: string;
  benefitsDescription: string;
}) {
  return (
    <section className="bg-[#C6DAFF]">
      <div className="max-w-8xl mx-auto px-6 py-16 sm:px-10 sm:py-20 lg:px-20 lg:py-28">
        <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">{benefitsTitle}</h2>
        {benefitsDescription && <p className="mt-2 text-xl">{benefitsDescription}</p>}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-10">
          {benefits?.map((benefit) => (
            <div
              key={benefit._key}
              className="bg-info-container border-on-info-container/10 relative flex flex-col gap-4 rounded-sm border p-6 lg:p-8"
            >
              <FaCheck className=" bg-on-info-container text-info-container h-8 w-8 rounded-full p-2" />
              <div>
                <h3 className="mb-2 break-words text-2xl font-semibold">{benefit.title}</h3>
                <p className="text-on-info-container lg:text-lg">{benefit.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

function VehicleValuationPage({ content }: PageProps) {
  const {
    overline,
    description,
    title,
    benefitsTitle,
    benefitsDescription,
    benefits,
    valuationStepsTitle,
    valuationStepsDescription,
    valuationSteps,
    image,
    bodyText,
    featureArticles,
    standardArticles,
  } = content;

  return (
    <>
      <HeroVehicleValuation
        title={title || ''}
        description={description || ''}
        overline={overline || ''}
        image={image}
      />
      <ValuationStepsComponent
        valuationSteps={valuationSteps || []}
        valuationStepsTitle={valuationStepsTitle || ''}
        valuationStepsDescription={valuationStepsDescription || ''}
      />
      <BenefitsComponent
        benefits={benefits || []}
        benefitsTitle={benefitsTitle || ''}
        benefitsDescription={benefitsDescription || ''}
      />
      {bodyText && (
        <div className="mx-auto my-10 max-w-3xl px-6 sm:px-10 lg:my-20">
          <PortableText value={bodyText} />
        </div>
      )}

      {standardArticles?.length > 0 && <StandardArticleList articles={standardArticles} />}
      {featureArticles?.length > 0 && <FeatureArticleList articles={featureArticles} />}
    </>
  );
}

export default VehicleValuationPage;
