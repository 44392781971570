import { useEffect, useMemo, useState } from 'react';
import Button from './Button';
import { ImageWithAltType } from '../types/imageWithAlt';
import { Image } from './Image';
import Icon, { Icons } from './icons/Icon';
import cn from '../utils/cn';
import getEnvFromUrl from '../utils/getEnvFromUrl';

type HeroVehicleValuationProps = {
  title: string;
  description: string;
  overline: string;
  image?: ImageWithAltType;
};

export const HeroVehicleValuation = (props: HeroVehicleValuationProps) => {
  const { title, description, overline, image } = props;
  const [isInputsVisible, setIsInputsVisible] = useState(true);

  const [registrationNumber, setRegistrationNumber] = useState('');
  const [mileage, setMileage] = useState('');
  const [purchaseHelpDesired, setPurchaseHelpDesired] = useState(false);
  const [errors, setErrors] = useState({
    registrationNumber: '',
    mileage: '',
  });
  const [isFormValid, setIsFormValid] = useState(false);

  const baseUrls = useMemo(
    () => ({
      dev: 'https://verdivurdering.dev.mollercloud.no',
      stage: 'https://verdivurdering.stage.mollercloud.no',
      prod: 'https://verdivurdering.mollerbil.no',
      test: 'https://verdivurdering.test.mollercloud.no',
    }),
    [],
  );

  const [vehicleValuationBaseUrl, setVehicleValuationBaseUrl] = useState(baseUrls.prod);

  useEffect(() => {
    setVehicleValuationBaseUrl(baseUrls[getEnvFromUrl(window.location.origin)]);
  }, [baseUrls]);

  useEffect(() => {
    const registrationNumberValid = errors.registrationNumber === '';
    const mileageValid = errors.mileage === '';
    const hasRegistrationNumber = registrationNumber !== '';
    const hasMileage = mileage !== '';
    setIsFormValid(registrationNumberValid && mileageValid && hasRegistrationNumber && hasMileage);
  }, [errors.registrationNumber, errors.mileage, registrationNumber, mileage]);

  const validateRegNumber = (value: string) => {
    if (!value) return 'Registreringsnummer er påkrevd';
    return '';
  };

  const validateKilometers = (value: string) => {
    if (!value) return 'Kilometerstand er påkrevd';
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(Number(value))) return 'Må være et tall';
    if (Number(value) <= 0) return 'Må være større enn 0';
    return '';
  };

  const handleRegNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.length > 7) return;
    setRegistrationNumber(value);
    setErrors((prev) => ({ ...prev, registrationNumber: validateRegNumber(value) }));
  };

  const handleKilometersChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    // eslint-disable-next-line no-restricted-globals
    if (value.length > 0 && isNaN(Number(value))) {
      return;
    }
    setMileage(value);
    setErrors((prev) => ({ ...prev, mileage: validateKilometers(value) }));
  };

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById('hero-cta-button');
      if (!element) return;

      const rect = element.getBoundingClientRect();
      const isInViewport = rect.top >= 0 && rect.bottom <= window.innerHeight;
      setIsInputsVisible(isInViewport);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleClick = () => {
    // if regnummer and kilometers are filled out and valid, go to another page
    if (isFormValid) {
      window.location.href = `${vehicleValuationBaseUrl}?registrationNumber=${registrationNumber}&mileage=${mileage}&purchaseHelpDesired=${purchaseHelpDesired}`;
      return;
    }
    setErrors((prev) => ({ ...prev, mileage: validateKilometers(mileage) }));
    setErrors((prev) => ({ ...prev, registrationNumber: validateRegNumber(registrationNumber) }));
    if (!isInputsVisible) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <>
      <section className="bg-primary text-background">
        <div
          className={cn(
            image?.asset ? 'hero-grid' : 'max-w-8xl relative mx-auto px-6 pb-4 sm:px-10 lg:px-20',
          )}
        >
          {/* TEXT */}
          <div
            className={cn(
              'max-w-4xl pb-0 pt-16 lg:pb-44 lg:pr-12 lg:pt-28',
              image?.asset ? '' : 'pb-0 pt-28',
            )}
          >
            {overline && (
              <span className="text-tertiary mb-2 inline-block text-xl font-semibold sm:text-2xl lg:text-3xl">
                {overline}
              </span>
            )}

            <h1 className="mb-5 text-4xl font-semibold leading-none lg:mt-2 lg:text-6xl text-balance">
              {title}
            </h1>
            {description && <p className="text-lg mb-4">{description}</p>}
            <div>
              <div className="relative lg:w-2/3 xl:w-1/2">
                <input
                  className={`mt-3 w-full rounded-[24px] border ${errors.registrationNumber ? 'border-tertiary' : 'border-black/20'} bg-white placeholder-primary/50 text-primary py-2 pl-4 pr-4 text-lg duration-300 focus:rounded-md focus:outline-none focus:ring-2 focus:ring-tertiary`}
                  placeholder="Registreringsnummer"
                  value={registrationNumber}
                  onChange={handleRegNumberChange}
                  aria-invalid={!!errors.registrationNumber}
                  aria-describedby={errors.registrationNumber ? 'regNumber-error' : undefined}
                />
              </div>
              {errors.registrationNumber && (
                <p id="regNumber-error" className="ml-4 mt-1 text-sm text-tertiary">
                  <span className="text-tertiary inline-flex items-center justify-center w-3 h-3 text-xs rounded-full border border-tertiary mr-2">
                    !
                  </span>
                  {errors.registrationNumber}
                </p>
              )}
            </div>
            <div>
              <div className="mt-3 relative lg:w-2/3 xl:w-1/2" id="hero-cta-button">
                <input
                  className={`w-full rounded-[24px] border ${errors.mileage ? 'border-tertiary' : 'border-black/20'} bg-white placeholder-primary/50 text-primary py-2 pl-4 pr-14 text-lg duration-300 focus:rounded-md focus:outline-none focus:ring-2 focus:ring-tertiary`}
                  placeholder="Kilometerstand"
                  value={mileage}
                  onChange={handleKilometersChange}
                  aria-invalid={!!errors.mileage}
                  aria-describedby={errors.mileage ? 'kilometers-error' : 'kilometers-hint'}
                />
                <span className="pointer-events-none absolute right-4 top-1/2 -translate-y-1/2 text-gray-500">
                  km
                </span>
              </div>
              {errors.mileage ? (
                <p id="kilometers-error" className="ml-4 pl-[1px] mt-1 text-sm text-tertiary">
                  <span className="text-tertiary inline-flex items-center justify-center w-3 h-3 text-xs rounded-full border border-tertiary mr-2">
                    !
                  </span>
                  {errors.mileage}
                </p>
              ) : (
                <p id="kilometers-hint" className="ml-4 pl-[1px] mt-1 text-sm">
                  Rund av til nærmeste 1000
                </p>
              )}
            </div>
            <div className="mt-3 pl-1 flex items-center ">
              <label htmlFor="helpBuyNewCar" className="text-lg ">
                <input
                  id="helpBuyNewCar"
                  type="checkbox"
                  className="h-4 w-4 rounded mr-2"
                  checked={purchaseHelpDesired}
                  onChange={(e) => setPurchaseHelpDesired(e.target.checked)}
                />
                Jeg ønsker også hjelp til kjøp av ny bil
              </label>
            </div>
          </div>
          {image?.asset && (
            <Image
              src={image}
              className="z-40 h-full w-full object-cover"
              width={1600}
              height={900}
              priority
            />
          )}
        </div>
      </section>

      <section
        className={cn(
          'bg-primary',
          'text-on-primary sticky top-0 z-30',
          'h-20',
          image?.asset ? 'lg:-mt-44 lg:mb-40' : 'lg:-mt-48 lg:mb-48',
        )}
      >
        <div className="max-w-8xl mx-auto items-center gap-4 px-6 py-4 sm:flex sm:h-full sm:px-10 sm:py-0 lg:px-20">
          <div className="flex w-full flex-col sm:flex-row sm:items-center sm:gap-4">
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleClick();
              }}
              id="hero-cta-button"
              className="bg-secondary min-w-full sm:min-w-0 border-transparent group inline-flex max-w-max justify-center items-center gap-3 rounded-[24px] px-5 py-2 duration-300 hover:rounded-md  active:rounded-[24px]"
            >
              Kom i gang
              {!isFormValid && !isInputsVisible && (
                <Icon
                  icon={Icons.ARROW_UP}
                  className="group-active:translate-x-none duration-300 group-hover:translate-x-2"
                />
              )}
            </Button>
          </div>
        </div>
      </section>
      <div className="lg:hidden">
        {image?.asset && (
          <Image src={image} className="object-cover" width={1600} height={900} priority />
        )}
      </div>
    </>
  );
};

export default HeroVehicleValuation;
